<template>
  <topbar
    :titulo="$t('USUARIOS.USUARIOS')"
    :subtitulo="$t('USUARIOS.PAGE_USUARIOS_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4">
        <div />
        <b-button @click="cadastrar()" variant="info" class="">
          {{ $t('USUARIOS.NOVO_USUARIO') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  props: {
    podeCadastrar: { type: Boolean, default: false },
  },
  components: { Topbar },
  computed: {
    breadcrumbs() {
      if (this.$route?.params?.linkRetornar === 'rh') {
        return [
          { titulo: this.$t('RH.RECURSOS_HUMANOS') },
          { titulo: this.$t('USUARIOS.USUARIOS') },
        ];
      } else {
        return [
          { titulo: this.$t('ADMIN.CONFIGURACOES') },
          { titulo: this.$t('USUARIOS.USUARIOS') },
        ];
      }
    },
  },
  methods: {
    cadastrar() {
      this.$emit('cadastrar', 'usuario');
    },
  },
};
</script>
