<template>
  <div>
    <titulo-usuario @cadastrar="openModal" />
    <tabela
      :usuarios="usuarios"
      @adicionar-perfis="openModal"
      @editar="openModal"
      @excluir="excluir"
    />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getUsuarios"
    />

    <modal-usuario
      :exibir="modais.usuario"
      :item="modais.usuarioDados"
      @atualizar="getUsuarios"
      @fechar="closeModal"
    />
    <modal-adicionar-perfis
      :exibir="modais.adicionarPerfis"
      :item="modais.adicionarPerfisDados"
      @atualizar="getUsuarios"
      @fechar="closeModal"
      @perfil-atualizado="getUsuarios"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import Permisoes from '@/components/mixins/permissoes';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import UsuarioService from '@/common/services/usuario/usuario.service';

// Components:
import Tabela from './components/Tabela';
import ModalAdicionarPerfis from './components/modais/Perfis';
import ModalUsuario from './components/modais/Usuario';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloUsuario from './components/TituloUsuario.vue';

export default {
  mixins: [Permisoes],
  components: {
    TituloUsuario,
    Tabela,
    Paginacao,
    ModalAdicionarPerfis,
    ModalUsuario,
  },
  data() {
    return {
      usuarios: [],
      modais: {
        adicionarPerfis: false,
        adicionarPerfisDados: {},
        usuario: false,
        usuarioDados: {},
      },
      paginacao: {
        total: 0,
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
      },
    };
  },
  mounted() {
    this.getUsuarios();
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getUsuarios() {
      this.$store.dispatch(START_LOADING);
      UsuarioService.listar(this.paginacao)
        .then(({ data }) => {
          this.usuarios = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.erro(this.$t('GERAL.ERRO'), err.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    excluir(item) {
      mensagem
        .confirmacao(
          null,
          this.$t('USUARIOS.CONFIRMAR_EXCLUIR').formatUnicorn({
            nome: item.nome,
          })
        )
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            UsuarioService.excluir(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('USUARIOS.MENSAGEM_EXCLUIDO')
                );
                this.getUsuarios();
              })
              .catch((err) => {
                mensagem.erro(this.$t('GERAL.ERRO'), err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
