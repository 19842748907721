<template>
  <modal
    id="modalPerfis"
    :exibir="exibir"
    :titulo="$t('GERAL.EDITAR')"
    :campos="$refs"
    @fechar="closeModal"
  >
    <div class="row">
      <div class="col-sm-12">
        <input-text
          ref="nome"
          v-model="nome"
          :label="$t('USUARIOS.NOME')"
          disabled
        />
        <input-checkbox
          ref="permissoes"
          v-model="form.perfisId"
          :label="$t('PERMISSOES.PERMISSOES')"
          :options="colecaoPerfis"
          stacked
        />
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="closeModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import PerfisService from '@/common/services/perfis/perfis.service';
import UsuarioService from '@/common/services/usuario/usuario.service';

// Models:
import { UsuarioPermissoes } from '@/common/models';

// Components:
import Modal from '@/components/modal/Modal';
import { InputText, InputCheckbox } from '@/components/inputs';

export default {
  components: {
    Modal,
    InputText,
    InputCheckbox,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: null },
  },
  data() {
    return {
      form: new UsuarioPermissoes({}),
      colecaoPerfis: [],
      nome: '',
    };
  },
  watch: {
    exibir(data) {
      if (data) {
        this.getPerfis();
        this.mostraNome();
        this.verificaForm();
      }
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getPerfis() {
      PerfisService.listarTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.colecaoPerfis = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        });
    },
    mostraNome() {
      return this.item ? (this.nome = this.item.nome) : (this.nome = '');
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      UsuarioService.adicionarPerfil(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('USUARIOS.PERFIL_ASSOCIADO_SUCESSO')
          );
          this.$emit('perfil-atualizado', this.form);
          this.closeModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES VALIDAÇÃO FORM:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    verificaForm() {
      if (this.item) {
        this.form.usuarioId = this.item.id;
        this.form.perfisId = this.item.perfis.map(({ id }) => id);
      } else {
        this.form = new UsuarioPermissoes({});
      }
      return this.form;
    },
    // FUNÇÕES MODAL:
    closeModal() {
      this.$emit('fechar', 'adicionarPerfis');
    },
  },
};
</script>
