<template>
  <modal
    id="modalUsuario"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    @fechar="closeModal"
  >
    <!-- Dados gerais do usuário -->
    <h6>
      {{ $t('GERAL.DADOS_DO_USUARIO') }}
    </h6>
    <div class="row">
      <div class="col-12">
        <InputSelectTyping
          ref="pessoaId"
          v-model="form.pessoaId"
          :placeholder="form.id ? form.nome : 'Selecione uma pessoa'"
          :label="$t('GERAL.PESSOA')"
          :options="pessoasSemUsuario"
          :isDisabled="ehEdicao"
          required
          @pesquisa-nome="getPessoasSemUsuario"
          :loading="loadingUsuario"
        />
      </div>

      <div class="col-12 mt-2">
        <input-text
          ref="email"
          v-model="form.email"
          :label="$t('GERAL.EMAIL')"
          type="email"
          :maxLength="70"
          required
        />
      </div>
    </div>
    <!-- SENHA: se for criação de usuário ou nova senha -->
    <div v-if="!ehEdicao" class="row">
      <div class="col-12 col-md-6">
        <input-text
          ref="senha"
          v-model="form.senha"
          type="password"
          :label="$t('GERAL.SENHA')"
          :minLength="6"
          required
        />
      </div>
      <div class="col-12 col-md-6">
        <input-text
          ref="confirmacaoSenha"
          v-model="form.confirmacaoSenha"
          type="password"
          :label="$t('GERAL.CONFIRMACAO_SENHA')"
          :minLength="6"
          :validate="validarSenha"
          required
        />
      </div>
      <div class="col-12 mt-2 listaRequisitos">
        <p>
          <b>{{ $t('GERAL.REQUISITOS_SENHA') }}</b>
        </p>
        <li>Tamanho mínimo: 6</li>
        <li>Conter no mínimo uma letra</li>
      </div>
    </div>
    <!-- SENHA: para salvar a edição -->
    <div v-if="ehEdicao" class="row">
      <div class="col-12">
        <input-text
          ref="senhaNova"
          v-model="form.senhaNova"
          type="password"
          :label="$t('GERAL.NOVA_SENHA')"
          :minLength="6"
          required
        />
      </div>
      <div class="col-12">
        <input-text
          ref="senhaAdministrador"
          v-model="form.senhaAdmin"
          type="password"
          :label="$t('GERAL.SENHA_ADMINISTRADOR')"
          :minLength="6"
          required
        />
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="closeModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services
import UsuarioService from '@/common/services/usuario/usuario.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';

// Components:
import Modal from '@/components/modal/Modal';
import { InputText, InputSelectTyping } from '@/components/inputs';
// import InputSelectMultiple from '@/components/inputs/InputSelectMultiple.vue';

export default {
  components: {
    Modal,
    InputText,
    // InputSelectMultiple,
    InputSelectTyping,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: Object },
  },
  data() {
    return {
      loadingUsuario: false,
      form: {},
      pessoasSemUsuario: [],
      pessoaId: '',
    };
  },
  watch: {
    exibir() {
      // this.getPessoasSemUsuario();
      this.form = this.item;
      this.form.pessoaId = { value: this.item.pessoaId, text: this.item.nome };
    },
  },
  computed: {
    titulo() {
      return this.item.id
        ? this.$t('GERAL.EDITAR')
        : this.$t('GERAL.CADASTRAR');
    },
    ehEdicao() {
      return this.item.id ? true : false;
    },
    novaSenha() {
      return this.item.id ? true : false;
    },
    validarSenha() {
      return this.form.senha === this.form.confirmacaoSenha;
    },
  },
  methods: {
    getPessoasSemUsuario(nome) {
      this.loadingUsuario = true;
      PessoaService.buscarPessoasSemUsuarioPesquisaNome(nome)
        .then((data) => {
          this.pessoasSemUsuario = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingUsuario = false;
        });
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.item.id ? this.editar() : this.criar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      UsuarioService.salvar({
        ...this.form,
        pessoaId: this.form.pessoaId.value,
      })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('USUARIOS.MENSAGEM_CADASTRADO')
          );
          this.$emit('atualizar');
          this.closeModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.form.senha = this.form.senhaAtual;
      this.form.confirmacaoSenha = this.form.senhaAtual;
      UsuarioService.salvar({
        ...this.form,
        pessoaId: this.form.pessoaId.value,
      })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('USUARIOS.MENSAGEM_EDITADO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$emit('atualizar');
          this.closeModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES VALIDAÇÃO FORM:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    // FUNÇÕES MODAL:
    closeModal() {
      this.pessoaId = '';
      this.$emit('fechar', 'usuario');
    },
  },
};
</script>
<style>
.listaRequisitos li {
  list-style-type: none;
}
</style>
