<template>
  <b-table
    :items="usuarios"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(perfis)="item">
      <b-badge variant="secondary" v-if="item.item.perfis.length == 0">
        {{ $t('USUARIOS.SEM_PERFIL_ASSOCIADO') }}
      </b-badge>
      <b-badge
        v-for="perfil in item.item.perfis"
        :key="perfil.id"
        :variant="usuarioPerfil(perfil.nome)"
        class="mr-1"
      >
        {{ perfil.nome }}
      </b-badge>
    </template>
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item.id)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="adicionaPerfis(item.item)">
          {{ $t('USUARIOS.GERENCIAR_PERFIS') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services
import UsuarioService from '@/common/services/usuario/usuario.service';

export default {
  props: {
    usuarios: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'nome',
          label: this.$t('USUARIOS.NOME'),
          sortable: true,
        },
        {
          key: 'userName',
          label: this.$t('USUARIOS.LOGIN'),
          sortable: true,
        },
        {
          key: 'perfis',
          label: this.$t('USUARIOS.PERFIS_ASSOCIADOS'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
      badgesPerfil: {
        admin: 'danger',
        responsavel: 'success',
        aluno: 'info',
      },
    };
  },
  methods: {
    // FUNÇÕES ESTÉTICAS:
    usuarioPerfil(tipo) {
      return this.badgesPerfil[tipo];
    },
    // FUNÇÕES OBTENÇÃO DE DADOS:
    editar(itemId) {
      this.$store.dispatch(START_LOADING);
      UsuarioService.buscarPorId(itemId)
        .then(({ data }) => {
          this.$emit('editar', 'usuario', data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES EMIT:
    adicionaPerfis(item) {
      this.$emit('adicionar-perfis', 'adicionarPerfis', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
